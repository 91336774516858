import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  readonly DEVPORTAL_ROLE = 'Devportal.Default';
  constructor(private http: HttpClient) {}

  public user$ = this.http
    .get<Me>(`${environment.http.usersBaseUrl}users/me`, {
      params: {
        sections: ['identity', 'roles'],
      },
    })
    .pipe(shareReplay());

  public hasDevPortalAccess$ = this.user$.pipe(
    map((user) => {
      const identityType =
        user.properties
          .find(
            (item: { name: string; value: string }) =>
              item.name === 'IdentityType'
          )
          ?.value?.toLowerCase() || '';
      return (
        ['viint', 'ek'].includes(identityType.toLowerCase()) ||
        this.hasRole(user) // this is to support KuMA if they got manually assigned the devportal role
      );
    })
  );

  public hasDevPortalPermissions$ = this.user$.pipe(
    map((user) => {
      const identityType =
        user.properties
          .find(
            (item: { name: string; value: string }) =>
              item.name === 'IdentityType'
          )
          ?.value?.toLowerCase() || '';
      return (
        this.hasRole(user) || ['viint'].includes(identityType.toLowerCase())
      );
    })
  );

  private hasRole(user: Me) {
    return user.applicationRoles?.includes(this.DEVPORTAL_ROLE);
  }
}

export interface Me {
  id: string;
  loginId: string;
  name: {
    title: string;
    firstName: string;
    familyName: string;
  };
  address: {
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
  company: {
    name: string;
    countryCode: string;
    id: string;
    vkorg: string;
  };
  contacts: {
    email: string;
    mobile: CustomerContactData | string;
    telephone: CustomerContactData | string;
  };
  userState: string;
  languageCode: string;
  locale: string;
  properties: { name: string; value: string }[];
  applicationRoles?: string[];
  businessRoles?: string[];
  contactId?: string;
}

export interface CustomerContactData {
  country: string;
  number: string;
  extension?: string;
}
