import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { SoftwareLicensesComponent } from './modules/footer/components/software-licenses/software-licenses.component';
import { AuthService } from './services/auth.service';
import { TranslationConfigService } from './services/translation-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public translationConfig: TranslationConfigService,
    public dialog: MatDialog
  ) {}

  languageSelection = environment.languageSelection;

  ngOnInit(): void {
    const savedLanguage = localStorage.getItem(
      this.translationConfig.localStorageLanguageKey
    );
    this.translationConfig.setLanguage(
      savedLanguage ? savedLanguage : undefined
    );
    this.authService.init();
  }

  openSoftwareLicenses() {
    this.dialog.open(SoftwareLicensesComponent);
  }
}
