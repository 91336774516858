<mat-toolbar class="navigation-row">
  <div
    class="container-fluid d-flex flex-row justify-content-between ps-1 pe-0"
  >
    <div class="d-flex align-items-center">
      <a routerLink="" class="d-none d-sm-block">
        <img
          src="../../../assets/img/viessmann-white.svg"
          alt="Viessmann Logo"
          class="logo"
        />
      </a>
      <a routerLink="" class="d-sm-none d-block">
        <img
          src="../../../assets/img/viessmann-white-mobile.svg"
          alt="Viessmann Logo"
          class="logo-mobile"
        />
      </a>
      <span class="dot"></span>
      <span class="mb-0 color-white app-title">{{ appName }}</span>
    </div>
    <div class="d-flex flex-row">
      <vih-login
        [lang]="translationConfigService.getLanguage()"
        (logoutEvent)="auth.removeTokenFromLocalStorage()"
      >
      </vih-login>
    </div>
  </div>
</mat-toolbar>
