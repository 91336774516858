<div style="width: 80vw" class="maintenance-container">
  <div class=".maintenance-content">
    <div class="row align-items-center">
      <div
        class="pt-5 col-11 col-lg-6 col-xl-4 d-flex flex-column align-items-start"
      >
        <div class="pt-5"></div>
        <h1>
          {{ "common.maintenance.title." + type.toLowerCase() | translate }}
        </h1>
        <p
          [innerHTML]="
            'common.maintenance.description.' + type.toLowerCase() | translate
          "
        ></p>
        <button
          [routerLink]="'/'"
          class="refresh-button mat-raised-button mat-button-base mat-primary"
        >
          {{ "common.maintenance.button.text" | translate }}
        </button>
      </div>
      <div class="col-11 col-lg-6 col-xl-8 p-3">
        <img src="/assets/media/maintenance.svg" alt="" />
      </div>
    </div>
  </div>
</div>
