import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageDropdownComponent } from './components/language-dropdown/language-dropdown.component';
import { SoftwareLicensesComponent } from './components/software-licenses/software-licenses.component';

const material = [
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  RouterModule,
  MatDialogModule,
  MatTableModule,
];

@NgModule({
  declarations: [LanguageDropdownComponent, SoftwareLicensesComponent],
  exports: [LanguageDropdownComponent, SoftwareLicensesComponent],
  imports: [CommonModule, TranslateModule.forChild(), ...material],
})
export class FooterModule {}
