import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, first, Observable, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  private apiHost: string;
  constructor(private auth: AuthService) {
    this.apiHost = new URL(environment.apiUrl).host;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpEventType.Response>> {
    if (request.headers.get('X-Skip-Interceptor') === 'true') {
      request = request.clone({
        headers: request.headers.delete('X-Skip-Interceptor'),
      });

      return next.handle(request);
    }
    request = request.clone({
      withCredentials: false,
    });

    if (!request.url.includes(this.apiHost)) {
      return next.handle(request);
    }

    return this.auth.csrfToken$.pipe(
      first((val) => {
        return val !== undefined;
      }),
      switchMap((value) => {
        return next.handle(
          request.clone({
            headers: request.headers.set('Authorization', `CSRF ${value}`),
          })
        );
      }),
      catchError((e) => {
        if (e instanceof HttpErrorResponse && e.status === 401) {
          // refresh CSRF token and retry active request if fetching of token succeed
          return this.auth
            .fetchNewCsrfToken()
            .pipe(switchMap(() => this.intercept(request, next)));
        }
        throw e;
      })
    );
  }
}
