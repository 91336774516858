import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog.component';

const material = [
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatButtonModule,
];

@NgModule({
  declarations: [TermsAndConditionsDialogComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    ...material,
  ],
})
export class TermsAndConditionsModule {}
