import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';

export interface Locale {
  locale: string;
  flag: string;
}

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent {
  @Input()
  public selectedCountry!: string;

  @Output()
  public languageChanged = new EventEmitter<string>();

  public availableLanguages = environment.app.supportedLanguages;

  public selectLocale(language: Locale) {
    this.languageChanged.emit(language.locale);
  }
}
