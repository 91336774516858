import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TranslationConfigService } from '../../services/translation-config.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  appName = 'Developer Portal';

  constructor(
    public auth: AuthService,
    public translationConfigService: TranslationConfigService
  ) {}
}
