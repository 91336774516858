import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MaintenanceGuard } from './modules/maintenance/services/maintenance.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, MaintenanceGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  {
    path: 'transactions',
    canActivate: [AuthGuard, MaintenanceGuard],
    loadChildren: () =>
      import('./modules/transactions/transactions.module').then(
        (m) => m.TransactionsModule
      ),
  },
  {
    path: 'legal-information',
    canActivate: [MaintenanceGuard],
    loadChildren: () =>
      import('./modules/legal-information/legal-information.module').then(
        (m) => m.LegalInformationModule
      ),
  },
  {
    path: 'incorrect-user-data',
    canActivate: [MaintenanceGuard],
    loadChildren: () =>
      import('./modules/incorect-user-data/incorrect-user-data.module').then(
        (m) => m.IncorrectUserDataModule
      ),
  },
  {
    path: 'forbidden',
    canActivate: [MaintenanceGuard],
    loadChildren: () =>
      import('./modules/forbidden/forbidden.module').then(
        (m) => m.ForbiddenModule
      ),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./modules/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
