<div>
  <button mat-button [matMenuTriggerFor]="menu1">
    <span class="flag flag-{{ selectedCountry.toLowerCase() }}"></span>
    <span class="dsw-link">{{
      "footer.language_dropdown.language." + selectedCountry.toLowerCase()
        | translate
    }}</span>

    <mat-icon class="down_arrow">keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #menu1 overlapTrigger="false">
    <button
      *ngFor="let language of availableLanguages"
      (click)="selectLocale(language)"
      mat-menu-item
    >
      <span class="flag flag-{{ language.flag }}"></span>
      <span>
        {{
          "footer.language_dropdown.language." +
            language.locale.toLocaleLowerCase() | translate
        }}</span
      >
    </button>
  </mat-menu>
</div>
