<div class="maintenance d-flex flex-column" *ngIf="isVisible">
  <div
    class="py-2 container d-flex justify-content-start align-items-center gap-4"
    *ngIf="isPartialOutage"
  >
    <mat-icon>offline_bolt</mat-icon>
    <span [innerHTML]="'common.maintenance.message.partial' | translate"></span>
  </div>
  <div
    class="py-2 container d-flex justify-content-start align-items-center gap-4"
    *ngIf="maintenance"
  >
    <mat-icon>construction</mat-icon>
    <span
      *ngIf="maintenance"
      [innerHTML]="
        'common.maintenance.message.planned'
          | translate
            : {
                from: maintenance.start_date | date: dateFormat,
                until: maintenance.end_date | date: dateFormat
              }
      "
    ></span>
  </div>
</div>
