import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'software-licenses',
  templateUrl: './software-licenses.component.html',
  styleUrls: ['./software-licenses.component.scss'],
})
export class SoftwareLicensesComponent implements OnInit {
  public data!: { [key: string]: OpenSourceLicense };
  constructor(private http: HttpClient) {}
  public displayedColumns = ['name', 'licenses', 'url'];
  public licenses = this.http.get<{ [key: string]: OpenSourceLicense }>(
    '/assets/licenses.json'
  );

  public ngOnInit() {
    this.http
      .get<{ [key: string]: OpenSourceLicense }>('/assets/licenses.json')
      .subscribe(
        (response) => {
          this.data = response;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      );
  }

  public convertedLicenses = () => {
    return Object.entries(this.data).map(([name, element]) => ({
      name,
      ...element,
    }));
  };

  isCustomLicense(licenses: string | string[]): boolean {
    if (Array.isArray(licenses)) {
      return false;
    } else {
      return licenses.startsWith('Custom:');
    }
  }

  extractCustomName(licenses: string): string {
    return this.isCustomLicense(licenses) ? 'Custom' : licenses;
  }

  extractCustomUrl(licenses: string): string {
    return this.isCustomLicense(licenses)
      ? licenses.replace(/^Custom:\s*/, '')
      : '';
  }
}

interface OpenSourceLicense {
  licenses: string;
  repository: string;
  publisher: string;
  email: string;
  path: string;
  licenseFile: string;
}
