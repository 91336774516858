<div mat-dialog-title class="dialog-head">
  <div class="dialog-title p-3">
    <h3>Open-source licenses</h3>
  </div>
</div>
<div mat-dialog-content class="m-0 p-0">
  <table
    class="main-table"
    mat-table
    *ngIf="licenses | async"
    [dataSource]="convertedLicenses()"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Package name</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="licenses">
      <th mat-header-cell *matHeaderCellDef>License</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.licenses">
          <ng-container *ngIf="isCustomLicense(element.licenses)">
            <a target="_blank" [href]="extractCustomUrl(element.licenses)">{{
              extractCustomName(element.licenses)
            }}</a>
          </ng-container>
          <ng-container *ngIf="!isCustomLicense(element.licenses)">
            {{ element.licenses }}
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef>Url</th>
      <td mat-cell *matCellDef="let element">
        <a target="_blank" [href]="element.repository">{{
          element.repository
        }}</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
