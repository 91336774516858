import { Component } from '@angular/core';
import { Status, StatusSummary } from '../services/status-page.service';

@Component({
  selector: 'check-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  public type: Status;
  constructor() {
    const state: StatusSummary = history.state;
    this.type = state?.status || Status.underMaintenance;
  }
}
