import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DswFooterComponent } from '@vi-succ/design-system-web';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './modules/footer/footer.module';
import { MaintenanceModule } from './modules/maintenance/maintenance.module';
import { NavigationModule } from './modules/navigation/navigation.module';
import { TermsAndConditionsModule } from './modules/terms-and-conditions/terms-and-conditions.module';
import { AuthService } from './services/auth.service';
import { InterceptorService } from './services/interceptor.service';
import { TranslationConfigService } from './services/translation-config.service';

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en-DE');

export function createTranslateLoader(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    {
      prefix: `${environment.http.translationBaseUrl}/phraseapp-proxy/projects/cb86c416129a38c164e966dc76eb90c0/locales/`,
      suffix: '/download?file_format=i18next',
    },
    {
      prefix: `${environment.http.translationBaseUrl}/phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/`,
      suffix: '/download?file_format=simple_json',
    },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
      isolate: false,
    }),
    NavigationModule,
    FooterModule,
    TermsAndConditionsModule,
    MaintenanceModule,
    DswFooterComponent,
  ],
  providers: [
    TranslationConfigService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
