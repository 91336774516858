import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from '../../../environments/environment';
import { NavigationComponent } from './navigation.component';

const material = [MatToolbarModule, MatIconModule, MatButtonModule];

@NgModule({
  declarations: [NavigationComponent],
  imports: [
    ...material,
    CommonModule,
    GlobalHeaderModule.forRoot({
      appId: 'developer-portal',
      baseUrl: environment.globalHeader.baseUrl,
      accountAppUrl: environment.globalHeader.accountAppUrl,
    }),
    RouterModule,
  ],
  exports: [NavigationComponent],
})
export class NavigationModule {}
