import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationConfigService {
  readonly localStorageLanguageKey = 'lang';
  readonly defaultLanguage = 'en';
  readonly supportedLanguages = [
    'de', // German
    'en', // English
  ];

  private currentLanguage$: BehaviorSubject<string> =
    new BehaviorSubject<string>(environment.app.defaultLanguage);

  constructor(private translate: TranslateService) {}

  setLanguage(language?: string): void {
    const initialLanguage = environment.languageSelection
      ? navigator.language
      : this.defaultLanguage;
    const supportedLanguage = this.getSupportedLanguage(
      language ? language : initialLanguage
    );

    if (environment.languageSelection) {
      localStorage.setItem(this.localStorageLanguageKey, supportedLanguage);
    }

    this.currentLanguage$.next(supportedLanguage);
    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(supportedLanguage);
  }

  public getLanguage(): string {
    return this.currentLanguage$.value;
  }

  private getSupportedLanguage(language: string): string {
    const supportedLanguage = this.supportedLanguages.find((l) =>
      language.toLocaleLowerCase().startsWith(l.toLocaleLowerCase())
    );

    return supportedLanguage ? supportedLanguage : this.defaultLanguage;
  }

  public getBrowserCountry() {
    const culture = this.translate.getBrowserCultureLang()?.split('-')[1];
    return culture ? culture : '';
  }
}
