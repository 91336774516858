import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { IdentityService } from '../services/identityService';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private userService: IdentityService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    if (this.auth.hasValidCsrfToken()) {
      !this.userService.hasDevPortalAccess$.subscribe((hasDevPortalAccess) => {
        if (!hasDevPortalAccess) {
          this.router.navigate(['/forbidden']);
        }
      });

      return of(true);
    }

    // start csrf token authentication process
    return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
  }
}
