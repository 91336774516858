import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private CSRF_TOKEN_STORAGE_KEY = 'dev_csrf_token';

  public csrfToken$ = new BehaviorSubject<string | undefined>(undefined);
  private fetchNewCsrfToken$: Observable<{ token: string }> = new Observable(
    undefined
  );

  private httpClient: HttpClient;
  private jwtHelper: JwtHelperService;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
    this.jwtHelper = new JwtHelperService();
  }

  public hasValidCsrfToken(): boolean {
    const token = this.getCsrfToken();
    return !!token && this.isCsrfTokenValid(token);
  }

  public getCsrfToken(): string | undefined {
    return this.csrfToken$.value;
  }

  public getTokenSub(token: string) {
    return this.jwtHelper.decodeToken(token).sub;
  }

  public init(): void {
    this.csrfToken$.subscribe((jwtToken) => {
      if (jwtToken) {
        localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
      }
    });

    this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
      tap({
        error: () => this.redirectToLoginScreen(),
      }),
      share()
    );

    const token =
      this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

    if (token && this.isCsrfTokenValid(token)) {
      this.csrfToken$.next(token);
      // eslint-disable-next-line sonarjs/no-redundant-jump
      return;
    }
  }

  public fetchNewCsrfToken(): Observable<{ token: string }> {
    return this.fetchNewCsrfToken$;
  }

  public getCsrfTokenRequest() {
    return this.httpClient
      .post<{ token: string }>(
        `${environment.auth.baseUrl}/auth/v1/saml/csrf`,
        {
          appId: environment.auth.appId,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map((resp) => {
          this.csrfToken$.next(resp.token);
          return resp;
        })
      );
  }

  private getCsrfTokenFromLocalStorage(): string | null {
    return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
  }

  private getCsrfTokenFromUrl(): string | void {
    const hash = window.location.hash;
    if (hash?.startsWith('#token=')) {
      const value = new HttpParams({ fromString: hash }).get('#token');
      window.location.hash = ''; // remove token from url
      return value || undefined;
    }
  }

  public redirectToLoginScreen(): void {
    window.location.href = `${environment.auth.baseUrl}/saml/sso/request?appId=${environment.auth.appId}`;
  }

  private isCsrfTokenValid(token: string): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(token);
    } catch (_) {
      return false;
    }
  }

  public removeTokenFromLocalStorage() {
    localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
  }

  public logout(redirectUrl: string): void {
    localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
    const form = window.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute(
      'action',
      `${environment.auth.baseUrl}/auth/v1/saml/logout?redirectUrl=${redirectUrl}`
    );
    form.setAttribute('target', '_self');

    window.document.body.appendChild(form);
    form.submit();
  }
}
