<div class="root h-100 d-flex flex-column">
  <div class="navbar flex-grow-0">
    <app-navigation></app-navigation>
  </div>
  <div class="content flex-grow-1">
    <check-maintenance-message></check-maintenance-message>
    <router-outlet></router-outlet>
  </div>
  <div class="footer flex-grow-0">
    <dsw-footer>
      <li link>
        <a class="dsw-link" (click)="openSoftwareLicenses()">{{
          "footer.legal.licenses" | translate
        }}</a>
      </li>
      <div *ngIf="languageSelection" after-links>
        <app-language-dropdown
          [selectedCountry]="translationConfig.getLanguage()"
          (languageChanged)="translationConfig.setLanguage($event)"
        ></app-language-dropdown>
      </div>
    </dsw-footer>
  </div>
</div>
