import { Component, OnInit } from '@angular/core';
import {
  PlannedMaintenance,
  Status,
  StatusPageService,
  StatusSummary,
} from '../services/status-page.service';

@Component({
  selector: 'check-maintenance-message',
  templateUrl: './maintenance-message.component.html',
  styleUrls: ['./maintenance-message.component.scss'],
})
export class MaintenanceMessageComponent implements OnInit {
  public isVisible = false;
  public maintenance?: PlannedMaintenance;
  public isPartialOutage?: boolean;

  public dateFormat = 'dd.MM.yyyy HH:mm';

  constructor(private statusPage: StatusPageService) {}

  ngOnInit(): void {
    this.statusPage.getStatus().subscribe((status: StatusSummary) => {
      const partialOutageTypes = [
        Status.partialOutage,
        Status.degradedPerformance,
      ];
      this.isPartialOutage = partialOutageTypes.includes(status.status);
      this.maintenance = this.getMaintenance(status);
      this.isVisible = this.isPartialOutage || !!this.maintenance;
    });
  }

  private getMaintenance(
    status: StatusSummary
  ): PlannedMaintenance | undefined {
    const maintenance = status.next_maintenance;
    if (maintenance) {
      // start showing maintenance message 7 days before start date
      const startDate = new Date(maintenance.start_date);
      const sevenDaysInMilliSeconds = 604800000;
      const timeToShow = new Date(
        startDate.getTime() - sevenDaysInMilliSeconds
      );
      const now = new Date();
      const isWeekAway = now.getTime() > timeToShow.getTime();

      if (isWeekAway) {
        return maintenance;
      }
    }
    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }
}
